<template>
  <div>
    <lms-card bottomMargin>
      <lms-card-title>
        <template #icon>
          <v-icon>mdi-database</v-icon>
        </template>

        {{ $tt.capitalize($t('settings.names.data')) }}
      </lms-card-title>

      <lms-card-item clickable @click="goTo('settings-training-type-list')">
        {{ $tt.capitalize($t('settings.names.trainingType')) }}

        <template #actions>
          <v-icon>mdi-menu-right</v-icon>
        </template>
      </lms-card-item>

      <lms-card-item clickable @click="goTo('settings-user-category-list')">
        {{ $tt.capitalize($t('settings.names.userCategory')) }}

        <template #actions>
          <v-icon>mdi-menu-right</v-icon>
        </template>
      </lms-card-item>

      <lms-card-item clickable @click="goTo('settings-country-list')">
        {{ $tt.capitalize($t('settings.names.countryDist')) }}

        <template #actions>
          <v-icon>mdi-menu-right</v-icon>
        </template>
      </lms-card-item>
    </lms-card>
  </div>
</template>

<script lang="js">
export default {
  methods: {
    goTo(name) {
      this.$router.push({ name })
    }
  }
}
</script>
