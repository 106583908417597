<template>
  <div>
    <f-page-header :title="title" :prevLinks="[]" />

    <lms-default-loader v-if="isLoading" />

    <f-container topBottom v-if="!isLoading">
      <f-config-panel />
      <f-data-panel />
      <f-functions-panel />
    </f-container>
  </div>
</template>

<script lang="js">
import FConfigPanel from '../../components/views/settings/ConfigurationsPanel.vue';
import FDataPanel from '../../components/views/settings/DataPanel.vue'
import FFunctionsPanel from '../../components/views/settings/FunctionsPanel.vue'

export default {
  components: {
    FConfigPanel,
    FDataPanel,
    FFunctionsPanel
  },
  computed: {
    title() {
      return this.$t("labels.settings")
    }
  },
  created() {
    this.$store.commit('system/loading', false);
  }
};
</script>
