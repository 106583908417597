<template>
  <div>
    <lms-card bottomMargin>
      <lms-card-title>
        <template #icon>
          <v-icon>mdi-cog-outline</v-icon>
        </template>

        {{ $tt.capitalize($t('settings.names.config')) }}
      </lms-card-title>

      <lms-card-item clickable @click="goTo('settings-time-between-exams')">
        {{ $t('settings.names.time_wait_exam_tries') }}

        <template #actions>
          <v-icon>mdi-pencil</v-icon>
        </template>
      </lms-card-item>

      <lms-card-item clickable @click="goTo('settings-max-exam-tries')">
        {{ $t('settings.names.max_exam_tries') }}

        <template #actions>
          <v-icon>mdi-pencil</v-icon>
        </template>
      </lms-card-item>
    </lms-card>
  </div>
</template>

<script lang="js">
export default {
  methods: {
    goTo(name) {
      this.$router.push({ name })
    }
  }
}
</script>
